@import url("fonts.css");

body {
  cursor: default;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

* {
  margin: 0px;
  padding: 0px;
}

:focus,
.form-control:focus,
input:focus {
  outline: none !important;
  box-shadow: none;
}

button,
button:hover,
a,
a:focus,
a:active,
a:hover {
  /* cursor: url("../images/partner/pointer.svg"), pointer !important; */
  cursor: pointer;
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

a,
a:hover {
  text-decoration: none;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
}

/* all margin */

.mr0 {
  margin: 0px;
}

.mr10 {
  margin: 10px;
}

.mr20 {
  margin: 20px;
}

.mr30 {
  margin: 30px;
}

.mr40 {
  margin: 40px;
}

.mr50 {
  margin: 50px;
}

.mr60 {
  margin: 60px;
}

.mr70 {
  margin: 70px;
}

.mr80 {
  margin: 80px;
}

.mr90 {
  margin: 90px;
}

.mr100 {
  margin: 100px;
}

/* margin top */

.mr-t0 {
  margin-top: 0px;
}

.mr-t10 {
  margin-top: 10px;
}

.mr-t20 {
  margin-top: 20px;
}

.mr-t30 {
  margin-top: 30px;
}

.mr-t40 {
  margin-top: 40px;
}

.mr-t50 {
  margin-top: 50px;
}

.mr-t60 {
  margin-top: 60px;
}

.mr-t70 {
  margin-top: 70px;
}

.mr-t80 {
  margin-top: 80px;
}

.mr-t90 {
  margin-top: 90px;
}

.mr-t100 {
  margin-top: 100px;
}

/* margin right */

.mr-r0 {
  margin-right: 0px;
}

.mr-r10 {
  margin-right: 10px;
}

.mr-r20 {
  margin-right: 20px;
}

.mr-r30 {
  margin-right: 30px;
}

.mr-r40 {
  margin-right: 40px;
}

.mr-r50 {
  margin-right: 50px;
}

.mr-r60 {
  margin-right: 60px;
}

.mr-r70 {
  margin-right: 70px;
}

.mr-r80 {
  margin-right: 80px;
}

.mr-r90 {
  margin-right: 90px;
}

.mr-r100 {
  margin-right: 100px;
}

/* margin bottom */

.mr-b0 {
  margin-bottom: 0px;
}

.mr-b10 {
  margin-bottom: 10px;
}

.mr-b20 {
  margin-bottom: 20px;
}

.mr-b30 {
  margin-bottom: 30px;
}

.mr-b40 {
  margin-bottom: 40px;
}

.mr-b50 {
  margin-bottom: 50px;
}

.mr-b60 {
  margin-bottom: 60px;
}

.mr-b70 {
  margin-bottom: 70px;
}

.mr-b80 {
  margin-bottom: 80px;
}

.mr-b90 {
  margin-bottom: 90px;
}

.mr-b100 {
  margin-bottom: 100px;
}

/* margin left */

.mr-l0 {
  margin-left: 0px;
}

.mr-l10 {
  margin-left: 10px;
}

.mr-l20 {
  margin-left: 20px;
}

.mr-l30 {
  margin-left: 30px;
}

.mr-l40 {
  margin-left: 40px;
}

.mr-l50 {
  margin-left: 50px;
}

.mr-l60 {
  margin-left: 60px;
}

.mr-l70 {
  margin-left: 70px;
}

.mr-l80 {
  margin-left: 80px;
}

.mr-l90 {
  margin-left: 90px;
}

.mr-l100 {
  margin-left: 100px;
}

/* padding  */

.pd0 {
  padding: 0px;
}

.pd10 {
  padding: 10px;
}

.pd15 {
  padding: 15px;
}

.pd20 {
  padding: 20px;
}

.pd30 {
  padding: 30px;
}

.pd40 {
  padding: 40px;
}

.pd50 {
  padding: 50px;
}

.pd60 {
  padding: 60px;
}

.pd70 {
  padding: 70px;
}

.pd80 {
  padding: 80px;
}

.pd90 {
  padding: 90px;
}

.pd100 {
  padding: 100px;
}

/* padding top */

.pd-t0 {
  padding-top: 0px;
}

.pd-t10 {
  padding-top: 10px;
}

.pd-t20 {
  padding-top: 20px;
}

.pd-t30 {
  padding-top: 30px;
}

.pd-t40 {
  padding-top: 40px;
}

.pd-t50 {
  padding-top: 50px;
}

.pd-t60 {
  padding-top: 60px;
}

.pd-t70 {
  padding-top: 70px;
}

.pd-t80 {
  padding-top: 80px;
}

.pd-t90 {
  padding-top: 90px;
}

.pd-t100 {
  padding-top: 100px;
}

/* padding right */

.pd-r0 {
  padding-right: 0px;
}

.pd-r10 {
  padding-right: 10px;
}

.pd-r20 {
  padding-right: 20px;
}

.pd-r30 {
  padding-right: 30px;
}

.pd-r40 {
  padding-right: 40px;
}

.pd-r50 {
  padding-right: 50px;
}

.pd-r60 {
  padding-right: 60px;
}

.pd-r70 {
  padding-right: 70px;
}

.pd-r80 {
  padding-right: 80px;
}

.pd-r90 {
  padding-right: 90px;
}

.pd-r100 {
  padding-right: 100px;
}

.pd-r170 {
  padding-right: 170px;
}

/* padding bottom */

.pd-b0 {
  padding-bottom: 0px;
}

.pd-b10 {
  padding-bottom: 10px;
}

.pd-b20 {
  padding-bottom: 20px;
}

.pd-b30 {
  padding-bottom: 30px;
}

.pd-b40 {
  padding-bottom: 40px;
}

.pd-b50 {
  padding-bottom: 50px;
}

.pd-b60 {
  padding-bottom: 60px;
}

.pd-b70 {
  padding-bottom: 70px;
}

.pd-b80 {
  padding-bottom: 80px;
}

.pd-b90 {
  padding-bottom: 90px;
}

.pd-b100 {
  padding-bottom: 100px;
}

/* padding left */

.pd-l0 {
  padding-left: 0px;
}

.pd-l10 {
  padding-left: 10px;
}

.pd-l20 {
  padding-left: 20px;
}

.pd-l30 {
  padding-left: 30px;
}

.pd-l40 {
  padding-left: 40px;
}

.pd-l50 {
  padding-left: 50px;
}

.pd-l60 {
  padding-left: 60px;
}

.pd-l70 {
  padding-left: 70px;
}

.pd-l80 {
  padding-left: 80px;
}

.pd-l90 {
  padding-left: 90px;
}

.pd-l100 {
  padding-left: 100px;
}

.blue-btn {
  background: #46aefd;
  border: 1px solid #46aefd;
  border-radius: 2px;
  font-size: 10px;
  color: #ffffff;
  height: 24px;
  font-family: Montserrat-Regular;
}

.blue-btn:hover {
  color: #46aefd;
  background: transparent;
}

.blue-btn:hover svg path {
  fill: #46aefd;
}

.border-blue-btn {
  background: #ffffff;
  border: 1px solid #46aefd;
  border-radius: 2px;
  font-size: 10px;
  color: #46aefd;
  height: 24px;
  font-family: Montserrat-Regular;
  line-height: 24px;
}

.border-blue-btn:hover {
  color: #46aefd;
  background: transparent;
}

.border-black-btn {
  background: transparent;
  border: 1px solid #080c27;
  border-radius: 2px;
  font-size: 10px;
  color: #080c27;
  height: 24px;
  font-family: Montserrat-Regular;
}

.border-black-btn:hover {
  background: #080c27;
  color: #ffffff;
}

.border-black-btn:hover svg path {
  fill: #ffffff;
}

.red-btn {
  background: #ff3939;
  border: 1px solid #ff3939;
  border-radius: 2px;
  font-size: 10px;
  color: #ffffff;
  height: 24px;
  font-family: Montserrat-Regular;
}

.red-btn:hover {
  color: #ff3939;
  background: transparent;
}

.green-btn {
  background: #51f074;
  border: 1px solid #51f074;
  border-radius: 2px;
  font-size: 10px;
  color: #ffffff;
  height: 24px;
  font-family: Montserrat-Regular;
}

.green-btn:hover {
  color: #51f074;
  background: transparent;
}

/* head css start */

.head-wrap {
  background-color: #46aefd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 175px;
  height: 40px;
  padding-right: 20px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}

.u-info {
  line-height: 6px;
  text-align: right;
  display: flex;
  align-items: center;
  flex-direction: column;
    justify-content: space-evenly;
}

.u-info h5 {
  font-size: 10px;
  color: #ffffff;
  font-family: Montserrat-Bold;
}

.u-info span {
  font-size: 9px;
  color: #ffffff;
}

.u-p-inn {
  width: 23px;
  height: 23px;
  background-color: #ffffff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  display: flex;
  align-items: center;
  margin-left: 4px;
  justify-content: center;
}

.u-p-inn img {
  max-width: 23px;
  max-height: 23px;
  object-fit: contain;
}

.home-link {
  padding: 0px 15px;
  line-height: 0;
}

.u-block {
  display: flex;
}

/* head css end */
/* Sidebar css start */

.side-bar-wrap {
  max-width: 164px;
  width: 100%;
  flex: 164px;
  background: #f0f0f0;
  position: fixed;
  overflow-y: auto;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
}

.side-bar-wrap::-webkit-scrollbar {
  width: 0px;
}

.logo-block {
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
  padding: 6px 0px;
  position: sticky;
  top: 0px;
  background: #f0f0f0;
  height: 40px;
  box-sizing: border-box;
}

.nav-group .g-title {
  font-size: 10px;
  color: #787878;
  background: #082135;
  height: 16px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.nav-group ul li:first-child .g-title {
  margin-top: 0px;
}

.nav-group ul {
  margin: 0px;
}

.nav-group ul li {
  list-style-type: none;
}

.nav-group ul li a {
  font-size: 10px;
  color: #ffffff;
  font-family: ARIAL;
  display: flex;
  align-items: center;
  padding: 6px 10px;
}

.nav-icon {
  width: 16px;
  height: auto;
  text-align: center;
  margin-right: 8px;
}

/* Sidebar css end */

/* NEW SIDE NAVBAR START */

.new-nav-list {
  margin: 0px;
  padding: 0px;
}

.new-nav-list li {
  list-style-type: none;
  position: relative;
}

.new-nav-list li a {
  display: flex;
  align-items: center;
  color: #080c27;
  font-family: Montserrat-Bold;
  font-size: 10px;
  padding: 5px 10px;
  position: relative;
}

.new-nav-list li.active-link,
.nav-list .nav-group ul li.active-link {
  background-color: #cde9fe;
}

.nav-list .nav-group ul li a {
  position: relative;
}

.new-nav-list li.active-link a::before,
.nav-list .nav-group ul li.active-link a::before {
  content: "";
  width: 4px;
  height: 14px;
  background: #080c27;
  border-radius: 0px 2px 2px 0px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.new-nav-list li.active-link a,
.nav-list .nav-group ul li.active-link a {
  color: #080c27;
  font-family: Montserrat-Bold;
}

.new-nav-list li.active-link a svg path {
  fill: #080c27;
}

.nav-list .nav-group ul li.active-link a svg path {
  stroke: #080c27;
}

.new-nav-list .sub-menu {
  background: #cde9fe;
  height: 0;
  overflow: hidden;
  transition: all 0.5s linear 0s;
}

.new-nav-list li.active-link .sub-menu {
  display: block;
  transition: all 0.5s linear 0s;
  height: auto;
}

/* .new-nav-list .sub-menu .sub-active {
    background: #F4F9FF;
} */

.new-nav-list .sub-menu a::before {
  display: none;
}

.new-nav-list .sub-menu ul li {
  position: relative;
}

.new-nav-list .sub-menu ul li a {
  padding: 5px 0px 5px 29px;
  font-size: 10px;
  color: #444444;
  font-family: Montserrat-Regular;
}

.new-nav-list .sub-menu ul li a span {
  display: flex;
  width: 12px;
  height: 12px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 6px;
  border: 0.5px solid #444444;
  line-height: 12px;
  margin-right: 5px;
}

.new-nav-list .sub-menu ul li.sub-active a {
  font-family: Montserrat-Bold;
  color: #080c27;
}

.new-nav-list .sub-menu ul li.sub-active a span {
  background: #080c27;
  color: #ffffff;
}

.new-nav-list .sub-menu ul {
  position: relative;
}

.new-nav-list .sub-menu ul::after {
  content: "";
  width: 1px;
  height: calc(100% - 0.4em);
  position: absolute;
  background-color: #919191;
  left: 16px;
  top: -6px;
}

.new-nav-list .sub-menu ul li::after {
  content: "";
  position: absolute;
  top: 12px;
  transform: translateY(-50%);
  height: 1px;
  width: 10px;
  background-color: #919191;
  left: 16px;
}
.new-nav-list .sub-menu ul li > .sub-menu {
  padding: 0 16px;
}
/* NEW SIDE NAVBAR END */

/* Routing body css  strat */
.body-routing {
  width: 100%;
  height: calc(100vh - 4.4rem);
  background-color: #ffffff;
  overflow: auto;
  padding: 15px 15px 10px 180px;
  margin-top: 40px;
}

.page-wrap {
  display: flex;
  flex-direction: column;
}

.flex1 {
  flex: 1;
}

.page-head-title h3 {
  font-size: 10px;
  color: #080c27;
  font-family: Montserrat-Bold;
}

.page-head-title h5 {
  font-size: 10px;
  font-family: Montserrat-Regular;
  color: #000000;
}

/* routing body css end  */

/* head filter css start  */
.filter-wrap {
  background: #f0f0f0;
  border-radius: 2px;
  padding: 10px;
}

.filter-inputs input {
  max-width: 415px;
  width: 100%;
  height: 24px;
  background: #ffffff;
  border: 0.564531px solid rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 9px;
  box-sizing: border-box;
  padding: 5px 10px;
  font-family: Montserrat-Regular;
}

.filter-inputs {
  display: flex;
  align-items: center;
}

.search-btn {
  font-size: 10px;
  color: #ffffff;
  font-family: Montserrat-Regular;
  width: 94px;
  height: 23px;
  background: #46aefd;
  border-radius: 2px;
  border: 1px solid #46aefd;
  margin-left: 8px;
}

::-webkit-input-placeholder {
  color: #a3a3a3;
}

::-moz-placeholder {
  color: #a3a3a3;
}

:-ms-input-placeholder {
  color: #a3a3a3;
}

:-moz-placeholder {
  color: #a3a3a3;
}

/* head filter css end  */

/* table css start  */
.table-center {
  text-align: center;
}

/* table css start  */
.cus-table table {
  width: 100%;
}

.cus-table table thead tr th {
  font-size: 10px;
  color: #080c27;
  font-family: Montserrat-Bold;
  padding: 6px 10px;
  background-color: #cde9fe;
  vertical-align: middle;
  /* border: 1px solid #E1E0E0; */
  position: sticky;
  top: 0px;
}

.cus-table table tbody tr td {
  font-size: 10px;
  color: #080c27;
  font-family: Montserrat-Regular;
  padding: 3px 10px;
  border: 1px solid #e1e0e0;
  vertical-align: middle;
}

.blank-btn {
  display: inline-flex;
  align-items: center;
}

.blank-btn button {
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0px 8px;
  background: transparent;
  border: 1px solid #000000;
  border-radius: 2px;
}

.max-h-table {
  max-height: 500px;
  overflow: auto;
  position: relative;
}

.cus-pagination .page-link {
  border: 0px;
  font-size: 10px;
  color: #000000;
  font-family: Montserrat-Regular;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  text-align: center;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  width: auto;
  padding: 0px;
  /* margin: 0px 20px; */
}

.page-item.active .page-link {
  background-color: #46aefd;
  border-color: #46aefd;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
}

.page-item:first-child .page-link svg {
  margin-right: 5px;
}

.page-item:last-child .page-link svg {
  margin-left: 5px;
}

.xs-logo-head {
  display: none;
}

.xs-m-close-btn {
  display: none;
}

/* Cus Modal css start  */
.cus-modal .modal-header .btn-close {
  background: none;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}

.cus-modal .modal-header {
  height: 24px;
  box-sizing: border-box;
  padding: 5px 15px;
  background: #46aefd;
}

.cus-modal .modal-header .modal-title {
  font-size: 10px;
  color: #ffffff;
  font-family: Montserrat-Bold;
  display: flex;
  align-items: center;
}

.cus-modal .modal-content {
  border-radius: 4px 4px 0px 0px;
  border: 0px;
}

.cus-modal .modal-content .modal-body {
  padding: 15px 15px;
}

.cus-modal .modal-content .modal-footer {
  padding: 15px;
}

@media (min-width: 576px) {
  .modal-dialog.modal360 {
    max-width: 360px;
    margin: 1.75rem auto;
  }

  .modal-dialog.modal552 {
    max-width: 552px;
    margin: 1.75rem auto;
  }
}

@media (min-width: 991px) {
  .modal-dialog.modal772 {
    max-width: 772px;
    margin: 1.75rem auto;
  }
}

/* Input css start  */

.inp-lable-title {
  font-size: 10px;
  color: #000000;
  font-family: Montserrat-Bold;
}

.cus-control {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  height: 20px;
  font-size: 10px;
  padding: 2px 10px;
  font-family: Montserrat-Regular;
}

select.cus-control {
  background: #ffffff url("../images/select-arrow.png") no-repeat 98% 50%;
  background-size: 10px;
}

.mr-b15 {
  margin-bottom: 15px;
}

.breadcrumb-block .breadcrumb {
  background-color: transparent;
  align-items: center;
  padding: 0px;
}

.breadcrumb-item {
  line-height: 10px;
  position: relative;
  padding: 0px 10px 0px 0px;
  margin: 0px 0px 0px 0px;
  height: 24px;
  display: flex;
  align-items: center;
}

.breadcrumb-item a {
  font-size: 10px;
  color: #8f8f8f;
  font-family: Montserrat-Regular;
}

.breadcrumb-item.active {
  font-family: Montserrat-Bold;
  font-size: 10px;
  color: #080c27;
  /* line-height: 0px; */
}

.breadcrumb-item + .breadcrumb-item::before {
  content: url("../images/r-arrow.png");
  display: inline-block;
  position: absolute;
  left: -4px;
  top: 50%;
  transform: translateY(-50%);
}

.large-title {
  font-size: 22px;
  color: #080c27;
  font-family: Montserrat-Bold;
}

/* msg modal css  */
.msg-body h4 {
  font-size: 14px;
  color: #080c27;
  font-family: Montserrat-Bold;
  line-height: 17px;
}

.msg-body {
  padding: 30px 15px 0px;
}

.cus-modal .modal-content .suc-msg-body {
  padding: 30px 30px 50px;
}

.suc-msg-body h3 {
  font-size: 24px;
  color: #080c27;
  font-family: Montserrat-Bold;
  margin: 10px 0px;
}

.suc-msg-body h5 {
  font-size: 12px;
  color: #080c27;
  font-family: Montserrat-Regular;
}

.trans-btn {
  background-color: transparent;
  border: 0px;
  padding: 0px;
}

.drag-box {
  min-height: 91px;
  background: #ffffff;
  border: 1px dashed rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.drag-box input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
}

.drag-box h5 {
  font-size: 10px;
  color: #000000;
  font-family: Montserrat-Regular;
  line-height: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.drag-box h5 button {
  text-decoration: underline;
  color: #46aefd;
}

.preview-img-box {
  max-width: 100%;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  padding: 8px;
  box-sizing: border-box;
  position: relative;
}

.up-img-block {
  height: 91px;
  width: 100%;
  background: rgba(205, 233, 254, 0.5);
  border-radius: 2px;
  position: relative;
}

.up-img-block img {
  max-height: 91px;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.u-file-name {
  background: #f0f0f0;
  border-radius: 2px;
  padding: 5px 10px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  display: flex;
  align-items: center;
}

.u-file-name h5 {
  font-size: 10px;
  color: #000000;
  font-family: Montserrat-Regular;
  flex: 1;
  margin: 0px 5px 0px;
}

.file-action-btn {
  display: flex;
  align-items: center;
}

.file-action-btn button {
  margin: 0px 3px;
  line-height: 0px;
}

.invite-card {
  border: 1px solid #e1e0e0;
  border-radius: 2px;
  padding: 14px 20px;
}

.invite-card h5 {
  color: #080c27;
  font-size: 10px;
  font-family: Montserrat-Bold;
}

.inn-blue-title {
  background: #cde9fe;
  border-radius: 2px;
  padding: 4px 11px;
}

.inn-blue-title h3 {
  font-size: 10px;
  color: #080c27;
  font-family: Montserrat-Bold;
}

.cus-gray-table {
  width: 100%;
  /* border: 1px solid #E1E0E0; */
}

.cus-gray-table thead th {
  position: sticky;
  top: -3px;
}

.cus-gray-table thead th,
.cus-gray-table tfoot th {
  font-size: 10px;
  color: #000000;
  font-family: Montserrat-Bold;
  background: #f0f0f0;
  padding: 10px;
}

.cus-gray-table tbody td {
  font-size: 10px;
  color: #000000;
  font-family: Montserrat-Regular;
  padding: 10px;
}

.l-img-box {
  width: 40px;
  height: 30px;
  background: #c4c4c4;
  border-radius: 2px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.l-img-box img {
  max-width: 35px;
  max-height: 30px;
  object-fit: contain;
}

.w-60 {
  width: 60px;
}

/* pro-list table  */
.pro-img-list {
  display: flex;
  flex-direction: column;
}

.img-list-table {
  position: relative;
}

.table-data-scroll {
  height: 100%;
  overflow: auto;
  position: absolute;
  width: 100%;
  border: 1px solid #f0f0f0;
}

.cus-scroll::-webkit-scrollbar {
  width: 5px;
}

.img-status {
  font-size: 12px;
  color: #000000;
  font-family: Montserrat-Regular;
}

.product-pre-wrap h5 {
  font-size: 10px;
  color: #000000;
  font-family: Montserrat-Bold;
  margin-bottom: 15px;
}

.cus-scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 5px;
}

.pro-d-card {
  border: 1px solid #e1e0e0;
  border-radius: 2px;
  padding: 12px 15px;
}

.static-info ul {
  margin: 0px;
  padding: 0px;
}

.static-info ul li {
  list-style-type: none;
}

.inline-info ul li {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.inline-info ul li span {
  display: block;
  width: 100%;
  max-width: 50%;
  flex: 50%;
}

.static-info ul li span {
  font-size: 10px;
  color: #000000;
  font-family: Montserrat-Regular;
  display: block;
  margin: 7px 0px;
  word-break: break-all;
}

.preview-block {
  width: 100%;
  padding: 18px;
  border: 1px solid #e1e0e0;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-block img {
  height: 100%;
  max-width: 100vw;
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.product-pre-wrap {
  max-width: 30%;
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
}

/* blue card css start  */
.c-f-card {
  border: 0.5px solid #e1e0e0;
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 15px;
}

.b-card-head {
  height: 24px;
  background: #cde9fe;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0px 15px;
}

.b-card-head h5 {
  font-size: 10px;
  color: #080c27;
  font-family: Montserrat-Bold;
  margin-bottom: 0px;
}

.b-card-body {
  border: 0.5px solid #e1e0e0;
  box-sizing: border-box;
  border-radius: 2px;
}

.group-img-card {
  padding: 15px;
}

@media (min-width: 992px) and (max-width: 1024px) {
  .product-pre-wrap {
    max-width: 40%;
    flex: 0 0 40%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .table-data-scroll {
    position: static;
    max-height: 300px;
  }

  .cus-gray-table thead th {
    white-space: nowrap;
  }

  .product-pre-wrap {
    max-width: 100%;
    flex: 0 0 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .product-pre-wrap h5 {
    width: 100%;
  }

  .preview-block {
    max-width: 49%;
    flex: 49%;
  }

  .pro-details {
    max-width: 49%;
    flex: 49%;
  }
}

@media (max-width: 767px) {
  .side-bar-wrap {
    transform: translateX(-100%);
    transition: all 0.3s linear 0s;
    overflow: visible;
  }

  .side-bar-wrap.show-sidebar {
    transform: translateX(0%);
    transition: all 0.3s linear 0s;
  }

  .body-routing {
    padding: 20px 15px;
    height: auto;
  }

  .max-h-table {
    max-height: 340px;
    margin-bottom: 30px;
  }

  .head-wrap {
    display: block;
    padding: 5px 15px;
  }

  .cus-table table thead tr th {
    white-space: nowrap;
  }

  .head-u-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .xs-logo-head {
    display: flex;
    align-items: center;
  }

  .toggle-btn {
    background-color: transparent;
    border: 0px;
    padding: 0px;
    margin-right: 10px;
  }

  .creat-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .creat-btn button {
    width: 100%;
    max-width: 49%;
    flex: 0 0 49%;
    padding: 0px;
  }

  .menu-backdrop {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.56);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9;
  }

  .xs-m-close-btn {
    position: absolute;
    left: 0;
    top: 10px;
    width: 30px;
    height: 30px;
    background: #f0f0f0;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 6px 6px 0px;
    z-index: 9;
    transition: all 0.3s linear 0s;
    transform: translateX(130px);
    opacity: 0;
  }

  .show-sidebar .xs-m-close-btn {
    transform: translateX(160px);
    transition: all 0.3s linear 0s;
    opacity: 1;
  }

  /* product img media start  */
  .pro-img-board .row {
    flex-direction: column;
  }

  .table-data-scroll {
    position: static;
    max-height: 300px;
  }

  .cus-gray-table thead th {
    white-space: nowrap;
  }

  .product-pre-wrap {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media (max-width: 576px) {
  .creat-btn {
    margin-top: 15px;
  }

  .page-head-title {
    display: block !important;
  }

  .page-head-title h3 {
    margin-bottom: 10px;
  }

  .bottom-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottom-btn button {
    width: 100%;
    max-width: 49%;
    flex: 0 0 49%;
    margin: 0px;
    padding: 5px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* Login css start  */
.login-wrap {
  background: linear-gradient(90deg, #0063a2 0%, #46aefd 100%);
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 0px;
  flex-direction: column;
}

.login-cell {
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-block {
  max-width: 360px;
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 40px 20px;
}

.f-link a {
  font-size: 11px;
  color: #080c27;
  text-align: right;
  font-family: Montserrat-Regular;
}

.l-btn {
  background: #46aefd;
  border-radius: 2px;
  height: 40px;
  border: 1px solid #46aefd;
  width: 100%;
  color: #ffffff;
  font-size: 14px;
  margin-top: 3rem;
  font-family: Montserrat-Bold;
}

.l-foot-links {
  width: 100%;
}

.l-foot-links ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 360px;
  margin: 0 auto;
}

.l-foot-links ul li {
  list-style-type: none;
}

.l-foot-links ul li a {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.6);
  font-family: Montserrat-Regular;
}

.l-foot-links p {
  margin: 0px;
  text-align: center;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.4);
  margin: 20px 0px;
  font-family: Montserrat-Regular;
}

.login-in-group label {
  font-size: 10px;
  color: #7b7b7b;
  font-family: Montserrat-Regular;
}

.login-in-group .cus-form-control {
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  font-size: 12px;
  color: #080c27;
  font-family: Montserrat-Regular;
}

.cus-check-box label {
  font-family: Montserrat-Regular;
  font-size: 11px;
  color: #080c27;
  cursor: pointer;
}

.cus-check-box label span {
  width: 17px;
  height: 17px;
  background: url("../images/uncheck.png") no-repeat center;
  background-size: 17px;
  display: block;
}

.cus-check-box label input:checked ~ span {
  background: url("../images/check.png") no-repeat center;
  background-size: 17px;
}

.cus-check-box label input {
  display: none;
}

.auth-form h3 {
  font-size: 20px;
  color: #080c27;
  font-family: Montserrat-Medium;
}

.auth-form h5 {
  font-size: 12px;
  color: #080c27;
  font-family: Montserrat-Regular;
}

.auth-form p {
  font-size: 10px;
  color: #606060;
  font-family: Montserrat-Regular;
}

.auth-form p span {
  font-family: Montserrat-Bold;
}

@media (max-width: 576px) {
  .login-block {
    max-width: 90%;
    width: 100%;
    padding: 20px 20px;
  }

  .auth-form {
    margin-top: 20px;
  }

  .l-foot-links {
    padding: 0px 15px;
  }

  .l-foot-links ul li a {
    font-size: 11px;
  }

  .l-foot-links ul li a {
    font-size: 10px;
  }

  .l-foot-links p {
    font-size: 9px;
  }
}

/* login css end */

.green-text {
  background: rgba(0, 197, 43, 0.05);
  border-radius: 50px;
  height: 16px;
  display: inline-block;
  min-width: 73px;
  color: #06b42d;
}

.red-text {
  background: rgba(255, 0, 0, 0.05);
  border-radius: 50px;
  height: 16px;
  display: inline-block;
  min-width: 73px;
  color: #ff0000;
}

/* view-detail css start  */
.static-info-card {
  padding: 10px;
  border: 1px solid #e1e0e0;
  border-radius: 2px 2px 0px 0px;
}

.static-info {
  line-height: 10px;
}

.static-info h5 {
  font-size: 10px;
  color: #000000;
  font-family: Montserrat-Bold;
  margin-bottom: 5px;
}

.static-info span {
  font-size: 10px;
  color: #000000;
  font-family: Montserrat-Regular;
}

.small-table thead tr th,
.small-table tbody tr td,
.small-table tfoot tr th {
  padding: 6px;
}

/* WOrk group responsive css  */
@media (max-width: 767px) {
  .action-btn button {
    white-space: nowrap;
  }

  .wgs-wrap .creat-btn button {
    padding: 0px 10px;
  }

  .static-info {
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .wgs-wrap .creat-btn {
    justify-content: center;
  }

  .static-info {
    margin-bottom: 20px;
  }
}

/* PV1 css start  */
.tote-box-wrap {
  border: 1px solid #080c27;
  padding: 10px;
}

.t-left-box .row,
.tote-box-wrap .row {
  margin: 0px -5px;
}

.tote-box-wrap div[class*="col"] {
  padding: 0px 5px;
}

.tote-card {
  border: 1px solid #080c27;
  font-size: 10px;
  color: #000000;
  font-family: Montserrat-Regular;
  margin-bottom: 12px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  text-align: center;
}

.l-tote-card {
  font-family: Montserrat-Regular;
  margin-bottom: 12px;
  width: 100%;
  height: 92%;
  border: 1px solid #080c27;
  font-size: 10px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  text-align: center;
}

.t-left-box .row .col-6:nth-child(+ n + 2) .tote-card {
  margin-bottom: 0px;
}

.multi-img-box {
  border: 1px solid #000000;
  border-radius: 10px;
  padding: 0px 15px 10px;
}

.m-img {
  margin-top: 10px;
}

.note-text p {
  font-size: 10px;
  color: #080c27;
  font-family: Montserrat-Regular;
}

.t-input input {
  border: 1px solid #8b8b8b;
  border-radius: 2px;
  height: 16px;
  font-size: 10px;
  color: #080c27;
  font-family: Montserrat-Regular;
  padding: 5px;
  text-align: center;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .tote-card-wrap .b-card-body {
    padding: 10px;
  }

  .tote-card-wrap .blue-card-panel {
    margin-top: 15px;
  }

  .b-card-head h5 {
    font-size: 9px;
  }

  .tote-card,
  .l-tote-card {
    font-size: 7px;
  }

  .ps-code-head h5 {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .tote-card-wrap .blue-card-panel {
    margin-bottom: 20px;
  }

  .tote-card-wrap .blue-card-panel .b-card-body {
    padding: 15px;
  }

  .note-text {
    margin-top: 0px;
  }

  .b-card-head {
    height: auto;
    padding: 5px;
  }

  .tote-card {
    font-size: 7px;
  }

  .ps-code-head {
    flex-direction: column;
    align-items: flex-start;
  }

  .ps-code-head button {
    margin-top: 10px;
  }
}

/* packout-station css start  */
.green-status {
  font-size: 10px;
  color: #51f074;
  font-family: Montserrat-Regular;
  cursor: auto !important;
}

.note-list h5 {
  font-size: 9px;
  color: #080c27;
  font-family: Montserrat-Bold;
}

.note-list ul {
  padding-left: 20px;
}

.note-list ul li {
  font-size: 10px;
  color: #080c27;
  font-family: Montserrat-Regular;
  margin: 5px 0px;
}

.mode-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.mode-block {
  line-height: 12px;
}

.mode-block h4 {
  font-size: 10px;
  color: #000000;
  font-family: Montserrat-Bold;
}

.mode-block span {
  font-size: 10px;
  color: #000000;
  font-family: Montserrat-Regular;
}

.ps-code-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ps-code-head h5 {
  font-size: 10px;
  color: #080c27;
  font-family: Montserrat-Bold;
}

.s-list-data ul li {
  list-style-type: none;
  display: flex;
  align-items: flex-start;
  line-height: 7px;
}

.s-list-data label,
.s-list-data h5 {
  font-size: 10px;
  color: #000000;
  font-family: Montserrat-Regular;
}

.s-list-data h5 {
  font-family: Montserrat-Bold;
}

.cus-modal .modal-content .print-data-body {
  padding: 20px 45px;
}

.s-list-data ul li {
  margin-bottom: 15px;
}

.error-alert {
  border: 1px solid #e1e0e0;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.error-con-box {
  padding-left: 10px;
  flex: 0 0 1;
}

.error-con-box h5 {
  font-size: 10px;
  color: #000000;
  font-family: Montserrat-Bold;
  margin-bottom: 8px;
}

.error-con-box p {
  font-size: 10px;
  color: #000000;
  font-family: Montserrat-Regular;
  margin: 0px;
}

.m-l-title {
  font-size: 14px;
  color: #080c27;
  font-family: Montserrat-Bold;
}

.action-btn button {
  white-space: nowrap;
}

.barcode-img {
  width: 102px;
  height: auto;
}

.barcode-img img {
  width: 100%;
}
.error-alert.checkbox-alert {
  display: flex;
  justify-content: space-between;
  background: #f0f0f0;
  border-radius: 2px;
  border: 0;
}
.checkbox-submit button {
  width: 131px;
  max-width: 100%;
  border: none;
  background: #46aefd;
  border: 0.555556px solid #46aefd;
  border-radius: 2px;
  font-family: Montserrat-Regular;
  color: #ffffff;
  height: 24px;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
}
.test-b {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 160px;
  width: 100%p;
}
.test-b label {
  width: 100%;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
  font-family: Montserrat-Bold;
  text-align: left;
}
.lpn-text-cont {
  max-width: 446px;
  margin: auto;
}
.test-asv {
  margin-top: 60px;
}
.test-b input {
  background: #ffffff;
  border: 0.564531px solid rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  font-size: 10px;
  line-height: 12px;
  color: #000000;
  font-family: Montserrat-Regular;
  padding: 4px;
}
.form-control.cus-control.test-select {
  height: 25px;
}
.test-resuls-h h5 {
  font-size: 10px;
  line-height: 12px;
  color: #000000;
  font-family: Montserrat-Bold;
  margin-bottom: 8px;
}
.avsv-label {
  font-size: 10px;
  line-height: 12px;
  color: #000000;
  font-family: Montserrat-Regular;
  width: 100%;
}
.vision-sys {
  padding: 15px 0;
}
.static-info.inline-info.vision-sys-list {
  width: 30%;
}
.vision-test-imges {
  width: 865px;
  max-width: 100%;
}

.breadcrumb-item.active a {
  color: #080c27;
  font-family: Montserrat-Bold;
  font-size: 10px;
}
.mr-b0 {
  margin-bottom: 0;
}

.cus-modal {
  display: block;
}
/* ------------------------------------------------------------ */
@media (max-width: 767px) {
  .ps-code-head {
    flex-direction: column;
    align-items: flex-start;
  }

  .ps-code-head button {
    margin-top: 10px;
  }

  .cus-modal .modal-content .print-data-body {
    padding: 15px;
  }

  .s-list-data label,
  .s-list-data h5 {
    margin-bottom: 8px;
  }

  .s-list-data ul li {
    flex-direction: column;
  }

  .b-card-body {
    padding: 15px;
  }

  .l-tote-card {
    font-size: 8px;
  }
}

@media (max-width: 576px) {
  .static-info.inline-info.vision-sys-list {
    width: 100%;
  }
}

.error-con-box.checkbox-wrph {
  display: flex;
  align-items: center;
}
.error-con-box.checkbox-wrph h5 {
  margin: 0 0 0 12px;
  font-size: 18px;
  line-height: 22px;
  font-family: Montserrat-Bold;
  color: #080c27;
}

/*  menu design css  */
