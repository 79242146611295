@font-face {
    font-family: ARIAL;
    src: url('../fonts/ARIAL.TTF');
}
@font-face {
    font-family: ARIALBD;
    src: url('../fonts/ARIALBD.TTF');
}

/* Montserrat fonts  */
@font-face {
    font-family: Montserrat-Regular;
    src: url('../fonts/Montserrat-Regular.ttf');
}
@font-face {
    font-family: Montserrat-Bold;
    src: url('../fonts/Montserrat-Bold.ttf');
}