.modal {
	display: unset !important;
}

.pagination-item {
	font-size: 10px !important;
	font-family: Montserrat-Regular !important;
}

.table-cell-nb {
	border-bottom: none !important;
	background-color: transparent !important;
}

.files-count {
	margin-top: 20px;
	font-size: 12px;
	font-family: Montserrat-Regular;
}

.active-sideoption {
	display: flex;
	align-items: center;
	color: #080c27;
	font-family: Montserrat-Bold;
	font-size: 10px;
	padding: 5px 10px;
	position: relative;
	background-color: #f0f0f0;
}

.active-sidesuboption p {
	font-family: Montserrat-Bold;
	color: #080c27;
}

.active-sidesuboption span {
	background: #080c27;
	color: #ffffff;
}

.selected-img {
	background-color: #cde9fe;
}

.form-error-msg {
	color: red;
	font-family: Montserrat-regular;
	font-size: 11px;
}

.link-style {
	color: black;
	font-family: "Montserrat-Regular";
	font-size: 12px;
	text-decoration: underline;
}

.link-container {
	text-align: center;
	margin-top: 10px;
}

.border-black-btn-disabled {
	background: transparent;
	border: 1px solid #a3a3a3;
	border-radius: 2px;
	font-size: 10px;
	color: #a3a3a3;
	height: 24px;
	font-family: Montserrat-Regular;
}

/* PDF CSS */
.pdfTopLabel {
	width: 300px;
	height: 200px;
	padding: 0px 20px;
	font-family: sans-serif;
	font-size: 10px;
	font-weight: bold;
	overflow: hidden;
	/* margin-left: 12px; */
}
.pdfTopLabel2 {
	width: 390px;
	height: 395px !important;
	padding: 20px;
	font-family: sans-serif;
	font-size: 14px;
	font-weight: bold;
	overflow: hidden;
    line-height: 2.1;
}

.pdfRxLabel {
	width: 280px;
	height: 100%;
	padding: 10px;
	font-family: sans-serif;
	font-size: 9px;
	display: flex;
	flex-direction: row;
	font-weight: bold;
	margin-left: 20px;
}
.pdfRxLabel2 {
	width: 350px;
	/* height: calc(100vh - 20em); */
	padding: 10px;
	font-family: sans-serif;
	font-size: 12px;
	/* display: flex;
	flex-direction: row; */
	font-weight: bold;
	margin-left: 20px;	
	padding-right: 0px;
	height: 350px;
}

.barcodeImageContainerNew img {
	width: 100%;
	height: auto;
	object-fit: contain;
}

.pdfRxLabel1 {
	width: 800px;
	height: 300px;
	padding: 20px;
	font-family: sans-serif;
	font-size: 12px;
	display: flex;
	flex-direction: row;
	font-weight: bold;
}

.pdfShippingLabel {
	width: 240px;
	/* height: calc(100vh - 15em); */
	padding: 40px 20px;
	font-family: sans-serif;
	padding-right: 0px;
	font-size: 10px;
	display: flex;
	flex-direction: column;
	font-weight: bold;
	box-sizing: border-box;
	line-height: 25px;
	margin-left: 20px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.pdfShippingLabel2 {
	width: 300px;
	font-family: sans-serif;
	padding-right: 8px;
	padding-left: 8px;
	font-size: 10px;
	display: flex;
	flex-direction: column;
	font-weight: bold;
	box-sizing: border-box;
	line-height: 25px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 288px;
}

.pdfRxLabelContainer {
	display: flex;
	flex-direction: column;

}

.cus-width {
	width: 600px;
	padding-left: 50px;
}

.justifySpaceBetween {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.justifyCenter {
	display: flex;
	justify-content: center;
}

.barcodeImage {
	max-width: 170px;
	max-height: 100px;
	object-fit: contain;
	transform-origin: bottom;
}

.barcodeImageContainer {
	width: 170px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cus-m-barcode {
	width: 100px;
	height: 50px;
	margin: 20px auto;
}

.barcodeImageContainer img,
.cus-b-barcode img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.cus-b-barcode {
	width: 70px;
	height: 50px;
	margin-left: auto;
}

.barcodeImageNew {
	max-height: 200px;
	object-fit: contain;
	transform-origin: bottom;
}

.barcodeImageContainerNew {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 20px;
	height: 165px;
}

.barcodeImageContainerNew img {
	height: 100%;
	object-fit: contain;
}

.barcodeImageModal {
	transform: rotate(90deg);
	width: 70px;
	height: 200px;
}

.barcodeImageContainerModal {
	max-width: 70px;
	max-height: 200px;
}

.action-btn {
	background: transparent;
	border: none;
}

.box1 {
	border: 1px solid black;
	width: 100%;
	height: 170px;
	display: flex;
	padding: 20px;
	justify-content: space-between;
}

.box1 div {
	font-size: 11px;
	font-family: Montserrat-Bold;
}

.box1-left {
	width: 30%;
	height: 100%;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
}

.box1-right {
	width: 60%;
	height: 100%;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	overflow-y: auto;
}

.tote-card1 {
	font-size: 20px;
	color: #000000;
	margin-bottom: 12px;
	width: 80px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	text-align: center;
	border: 1px solid #080c27;
	box-sizing: border-box;
	font-weight: bold;
}

.label-box {
	width: fit-content;
	height: auto;
	padding: 5px;
	border: 1px solid gray;
}

.boldSans {
  font-weight: bold;
  /* font-size: 10px; */
  font-family: sans-serif;
}

.regularSans {
  /* font-size: 10px; */
  font-family: sans-serif;
  font-weight: lighter;
}

.regularArial {
  /* font-size: 8px;   */
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
}
.text14{
	font-size: 12px;
}

.font200 {
	font-weight: 200;
}

.fillDropSelect {
	background-color: transparent;
	font-size: 11px;
    color: #ffffff;
    font-family: Montserrat-Bold;
    border: transparent;
}

.fillDropSelect option {
	color: #000000;
}

.MuiModal-root {
	z-index: 0 !important;
}

.base64QRCodeImg {
	width: 175px;
	height: 175px;
	margin-top: 10px;
}
.regurlarSans{
	font-weight: lighter;
}

.font12{
	font-size: 12pt !important;
}

.font10{
	font-size: 10pt !important;
}

.address {
	font-size: 8px;  
	font-family: Arial, Helvetica, sans-serif;
  }